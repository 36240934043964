import type { LocalRoutes } from './type';

export const ROUTE_SEGMENT: LocalRoutes = {
    account: 'conta',
    activate: 'ativar',
    active: 'ativo',
    ad: 'anuncio',
    agencies: 'agencias-consultores-gestores',
    agencyCompanies: 'agencias-imobiliarias',
    agents: 'consultores',
    archived: 'arquivado',
    business: 'empresa',
    callRequest: 'pedido-de-chamada',
    category: 'categoria',
    clipboard: 'guardados',
    companies: 'empresas',
    confirmPassword: 'confirme-a-senha',
    contacts: 'aplicacoes',
    developerCompanies: 'promotores-imobiliarios',
    developers: 'promotores-imobiliarios',
    domainSearch: 'resultados',
    editAd: 'editar-anuncio',
    emailNotifications: 'notificacoes-email',
    emails: 'emails',
    evaluate: 'valor',
    fbLoginCallbackPage: 'social-login-redirect',
    finance: 'financas',
    history: 'historico',
    investment: 'empreendimento',
    investments: 'empreendimentos',
    invoiceData: 'dados-de-faturacao',
    invoices: 'facturas',
    locationsMap: 'regioes',
    login: 'login',
    manage: 'gerir',
    moderated: 'moderado',
    mortgageBrowser: 'hipotecas',
    myaccount: 'conta-pessoal',
    newAd: 'novo-anuncio',
    newInvestment: 'novo-empreendimento',
    passwordChanged: 'senha-alterada',
    payments: 'pagamentos',
    preview: 'preview',
    pricing: 'precos',
    privateUsers: 'privado',
    profile: 'perfil',
    profileActivation: 'profile-activation',
    prolong: 'prolongar',
    promote: 'destacar',
    propertyEvaluation: 'avaliacao-de-imoveis',
    register: 'registo',
    registerAgent: 'consultor',
    registerBusiness: 'registo-de-empresa',
    registerBusinessAgency: 'agencia',
    renting: 'venda',
    reset: 'reset',
    restored: 'restaurado',
    savedAds: 'anuncios',
    savedSearches: 'pesquisas',
    schedulePromote: 'agendar-promocoes',
    search: 'anuncios',
    searchNotFound: 'nenhum-resultado',
    selling: 'arrendamento',
    settings: 'definicoes',
    sitemap: 'mapa-de-categorias',
    sublisting: 'anuncios',
    subscription: 'subscricao',
    success: 'sucesso',
    summary: 'resumo',
    thankYou: 'obrigado',
    unsubscribe: 'cancelar',
    unsubscribed: 'cancelado',
    updated: 'atualizado',
    waiting: 'esperando',
    wallet: 'carteira',
    cityLanding: 'imobiliario',
    crm: 'crm',
    authorization: 'authorization',
    status: 'status',
};
