import type { NestedPartial } from '@lib/utils/type';

import { BERRY, ERROR, GENERICS, GREY, GREY_UPDATED, SKY, SKY_DEPRECATED, TOMATO } from '../partials/colors';
import { extendTheme } from './extendTheme';
import type { RawTheme, Theme } from './type';

const IMOVIRTUAL2_THEME: NestedPartial<RawTheme> = {
    atoms: {
        button: {
            primary: {
                default: {
                    backgroundColor: SKY.x500,
                },
                hover: {
                    backgroundColor: SKY.x400,
                },
                focus: { backgroundColor: SKY.x400 },
                active: { backgroundColor: SKY.x600 },
            },
            action: {
                default: {
                    backgroundColor: TOMATO.x500,
                },
                hover: {
                    backgroundColor: TOMATO.x400,
                },
                focus: {
                    backgroundColor: TOMATO.x400,
                },
                active: {
                    backgroundColor: TOMATO.x600,
                },
            },
        },
        actionLink: {
            secondary: {
                default: { color: '#303030' },
                hover: { color: '#303030' },
                disabled: { color: GREY.x200 },
            },
        },
    },
    pages: {
        login: {
            login: {
                forgotPasswordLink: {
                    default: {
                        color: TOMATO.x500,
                    },
                    hover: {
                        color: TOMATO.x400,
                    },
                    focus: {
                        color: TOMATO.x400,
                    },
                    active: {
                        color: TOMATO.x600,
                    },
                },
            },
            resetPassword: {
                goBackLink: {
                    default: {
                        color: TOMATO.x500,
                    },
                    hover: {
                        color: TOMATO.x400,
                    },
                    focus: {
                        color: TOMATO.x400,
                    },
                },
            },
        },
        manage: {
            newInvestment: {
                thankYou: {
                    color: '#303030',
                    icon: {
                        color: '#3ec1cf',
                    },
                },
            },
        },
    },
    domains: {
        shared: {
            addItemButton: {
                default: {
                    backgroundColor: SKY.x500,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                hover: {
                    backgroundColor: SKY.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                focus: {
                    backgroundColor: SKY.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                active: {
                    backgroundColor: SKY.x600,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
            },
            badge: {
                primary: {
                    backgroundColor: BERRY.x400,
                },
                success: {
                    backgroundColor: SKY.x500,
                    color: GENERICS.white,
                },
                reversedColors: {
                    success: {
                        backgroundColor: SKY.x100,
                        color: SKY.x500,
                    },
                },
            },
            toggle: {
                switch: {
                    checked: { backgroundColor: SKY.x500 },
                    notChecked: { backgroundColor: GREY_UPDATED.x400 },
                    disabled: { backgroundColor: GREY_UPDATED.x200 },
                },
            },

            featureIcon: {
                included: {
                    color: SKY.x500,
                },
                notIncluded: {
                    color: GREY_UPDATED.x300,
                },
            },
            textButton: {
                primary: {
                    color: SKY_DEPRECATED.x500,
                },
            },
            link: {
                primary: {
                    default: {
                        color: SKY_DEPRECATED.x500,
                    },
                    hover: {
                        color: SKY_DEPRECATED.x400,
                    },
                },
            },
            modalInfoItem: {
                checkIcon: {
                    color: SKY.x500,
                },
            },
        },
        ad: {
            adSubscribe: {
                default: {
                    borderColor: SKY_DEPRECATED.x500,
                    color: SKY_DEPRECATED.x500,
                },
                hover: {
                    borderColor: SKY_DEPRECATED.x500,
                    color: SKY_DEPRECATED.x500,
                    backgroundColor: `${SKY_DEPRECATED.x500}1a`,
                },
                focus: {
                    borderColor: SKY_DEPRECATED.x500,
                    color: SKY_DEPRECATED.x500,
                    backgroundColor: `${SKY_DEPRECATED.x500}1a`,
                },
            },
            adLocation: {
                color: SKY_DEPRECATED.x500,
            },
            phoneNumber: {
                showNumber: {
                    default: {
                        borderColor: SKY_DEPRECATED.x500,
                        color: SKY_DEPRECATED.x500,
                    },
                    active: {
                        borderColor: SKY_DEPRECATED.x500,
                        color: SKY_DEPRECATED.x500,
                        backgroundColor: SKY_DEPRECATED.x50,
                    },
                },
            },
            investmentUnitsList: {
                link: {
                    color: SKY_DEPRECATED.x500,
                },
            },
            adAgencyBannerContent: {
                agencyName: {
                    color: SKY_DEPRECATED.x500,
                },
            },
            adUserAds: {
                color: SKY_DEPRECATED.x500,
            },
            adDescription: {
                color: GENERICS.text,
            },
            adHeader: {
                location: {
                    color: SKY_DEPRECATED.x500,
                },
                developer: {
                    primary: {
                        color: SKY_DEPRECATED.x500,
                    },
                    secondary: {
                        color: SKY_DEPRECATED.x500,
                    },
                },
                investment: {
                    link: {
                        primary: {
                            color: SKY_DEPRECATED.x500,
                        },
                        secondary: {
                            color: SKY_DEPRECATED.x500,
                        },
                    },
                },
            },
            adRemoteService: {
                titleIcon: {
                    color: TOMATO.x500,
                },
                tileButton: {
                    color: SKY_DEPRECATED.x500,
                },
            },
        },
        pricing: {
            tabsSwitcher: {
                icon: {
                    color: BERRY.x500,
                    backgroundColor: BERRY.x100,
                },
            },
            regularPricing: {
                rentRoomBanner: {
                    iconWrapper: {
                        fill: BERRY.x500,
                        backgroundColor: BERRY.x100,
                    },
                },
            },
            link: {
                color: SKY.x500,
            },
        },
        myAccount: {
            shared: {
                accountStatisticsReport: {
                    downloadLink: {
                        icon: { color: SKY.x500 },
                        label: { color: SKY.x500 },
                    },
                },
                promoteReleaseModal: {
                    slideIndicator: {
                        borderColor: SKY.x500,
                        active: {
                            backgroundColor: SKY.x500,
                        },
                    },
                },
            },
            paywall: {
                bundleSection: {
                    card: {
                        badge: {
                            backgroundColor: BERRY.x400,
                        },
                    },
                    rentRoomBundle: {
                        iconWrapper: {
                            backgroundColor: BERRY.x100,
                            fill: BERRY.x500,
                        },
                    },
                },
            },
            ads: {
                adsList: {
                    showFilters: {
                        color: SKY.x500,
                        hover: {
                            color: SKY.x400,
                        },
                    },
                },
                datePicker: {
                    day: {
                        default: {
                            backgroundColor: SKY.x100,
                        },
                        selected: {
                            backgroundColor: SKY.x500,
                        },
                    },
                    calendar: {
                        color: SKY.x500,
                    },
                },
                adItem: {
                    developerAdsButton: {
                        newUnitButton: {
                            color: SKY.x600,
                        },
                    },
                },
            },
            payments: {
                nextInvoiceTable: {
                    priceValue: {
                        color: SKY.x500,
                    },
                },
                pastInvoiceTable: {
                    status: {
                        paid: {
                            color: SKY.x500,
                        },
                        unpaid: {
                            color: ERROR.x500,
                        },
                    },
                    labelCell: {
                        color: SKY.x500,
                    },
                },
                sharedTables: {
                    tableHead: {
                        color: GREY_UPDATED.x500,
                    },
                    link: {
                        color: SKY.x500,
                    },
                },
            },
            summary: {
                accountBalance: {
                    checkInvoicesButton: {
                        color: SKY.x500,
                    },
                },
            },
        },
    },
};

export const THEME: Theme = extendTheme({ deprecated: IMOVIRTUAL2_THEME });

// Keep it for backward compatibility - usage in tests
export const imovirtualpt = THEME;
